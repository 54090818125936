import RestaurantIcon from './RestaurantIcon';

import RestaurantList from './RestaurantList';
import RestaurantCreate from './RestaurantCreate';
import RestaurantEdit from './RestaurantEdit';

export default {
    list: RestaurantList,
    create: RestaurantCreate,
    edit: RestaurantEdit,
    icon: RestaurantIcon,
};
