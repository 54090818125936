import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Login',
            password: 'Password',
            sign_in_error: 'Signin Failed',
        },
    },

    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
    },
    resources: {
        ingredients: {
            name: 'Ingredient |||| Ingredients',
            fields: {
                name_fr: 'Name Fr',
                name_en: 'Name En',
                name_es: 'Name Es',
                image:'Image',
                kcal:'Kcal',
                kj:'Kj',
                maxQuantity:'Max Quantity',
                proteins: 'Proteins',
                lipids: 'Lipides',
                sugar: 'Sucre',
                fruits: 'Fruits',
                cholesterol:'Cholesterol',
                calcium: 'Calcium',
                alcohol:'Alcohol',
                fiber:'Fiber',
                carbs: 'Carbs',
                sodium: 'Sodium',
                iron: 'Iron',
                macroType:'Macro Type',
                energy: 'Energy',
                history: 'History',
                created_at: 'Created At',
                updated_at: 'Updated At',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
        },
        meals: {
            name: 'Meal |||| Meals',
            fields: {
                name_fr: 'Name Fr',
                name_en: 'Name En',
                name_es: 'Name Es',
                image:'Image',
                kcal:'Kcal',
                kj:'Kj',
                protein: 'Protein',
                lipid: 'Lipid',
                carbohydrates: 'Carbohydrates',
                lactose: 'Lactose',
                fruits: 'Fruits',
                vegetables: 'Vegetables',
                energy: 'Energy',
                history: 'History',
                created_at: 'Created At',
                updated_at: 'Updated At',
                ingredients: 'Ingredients',
                categories: 'Categories',
                types:'Meal Type',
                type:'Meal',
                description_fr: 'Description FR',
                description_en: 'Description EN',
                description_es: 'Description ES',
                stock:'Stock',
                goal:'Goal',
                category:'Category',
                programKcal:'Program Kcal',
                file:'CSV File',
                ecommerce:{
                    label:'E-Commerce',
                    eneabled:'Enabled',
                    disabled:'disabled',
                    price:'Price',
                    goals:'Goals',
                    calories:'Calories',

                },
            },    
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
        },
        clients: {
            name: 'Client |||| Clients',
        },
        restaurants: {
            name: 'Restaurant |||| Restaurants',
            fields: {
                name: 'Name',
                first_name: 'Manager First Name',
                last_name: 'Manager Last Name',
                email:'Email',
                phone:'Phone',
                description: 'Description',
                created_at: 'Created At',
                updated_at: 'Updated At',
                password: 'Password',
                confirmPassword: 'Confirm password',
                zip:'Zip',
                image:'Image',
                name: 'Name',
                revenue: 'Revenue',
                ordres:'Orders',
                country:'Country',
                address:'Address',
                address1:'Address',
                address2:'Address 2',
                history: 'History',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
            page: {
                delete: 'Delete Restaurant',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    // 'Drop a picture to upload, or click to select it.',
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        orders: {
            name: 'Order |||| Orders',
            fields: {
                meal: 'Meal',
                image:'Image',
                price:'Price',
                quantity:'Quantity',
                history: 'History',
                created_at: 'Created At',
                updated_at: 'Updated At',
                ingredients: 'Ingredients',
                categories: 'Categories',
                types:'Meal Type',
                description: 'Description',
                stock:'Stock',
                total:'Total',
                user:'Client',
                restaurant:'Restaurant',
                status:'Status',
                paid:'Paid',
                ecommerce:{
                    label:'E-Commerce',
                    eneabled:'Enabled',
                    disabled:'disabled',
                    price:'Price',
                    goals:'Goals',
                    calories:'Calories',

                },
            },
        },
        notifications: {
            name: 'Notification |||| Notifications',
            fields: {
                message: 'Message',
                recipients:'Recipients',
                history:'History',
                created_at: 'Created At',
                updated_at: 'Updated At',
            },
            actions:{
                send:'Send'
            }
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                firstName: 'First Name',
                lastName: 'Last Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                email:'Email',
                phone:'Phone',
                size:'Size',
                weight:'Weight',
                birthdate:'Birthdate',
                balance:'Balance',
                created_at: 'Created At',
                updated_at: 'Updated At',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
    },
};
