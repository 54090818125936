import React, { Fragment } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Pagination,
    NumberInput,
    Filter,
    List,
    SelectInput,
    EditButton,
    NumberField,
    SelectField,
    EmailField,
    TextField,
    SearchInput,
    downloadCSV,
    TextInput,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';
import SegmentsField from './SegmentsField';
import SegmentInput from './SegmentInput';
import CustomerLinkField from './CustomerLinkField';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';
import NotificationBulkActionButton from './NotificationBulkActionButton';

const useStyles = makeStyles(
    {
        nb_commands: { color: 'purple' },
        balance: { color: 'brown' },
        search: {
            marginTop: 32,
        },
    },
    { name: 'RaSearchInput' }
);

const exporter = records => {
    // nom, prénom, Email, Téléphone, Genre, Pays, Ville, Age, Poids, Taille, Langue, Objectif, BC)
    const clientsForExport = records.map(client => {
        const clientForExport = {}; // omit backlinks and author
        clientForExport['Nom'] = client.name.last;
        clientForExport['Prénom'] = client.name.last;
        clientForExport['Email'] = client.email;
        clientForExport['Eél'] = client.phone;
        clientForExport['Genre'] = client.gender;
        clientForExport['Pays'] = client.country;
        clientForExport['ville'] = client.city;
        clientForExport['Age'] = client.age;
        clientForExport['Poids'] = client.weight;
        clientForExport['Taille'] = client.sanitizeRestProps;
        clientForExport['Langue'] = client.lang;
        clientForExport['Objectif'] = (() => {
            switch (client.goal) {
                case 1:
                    return 'Sèche';
                case 2:
                    return 'Perte de poids';
                case 3:
                    return 'Prise de Masse';
                case 4:
                    return 'Remise en forme';
                case 5:
                    return 'Maintien';
                default:
                    return 'Non-defini';
            }
        })();
        clientForExport['bc'] = client.dailyMetabolism;
        return clientForExport;
    });

    jsonExport(
        clientsForExport,
        {
            // headers: ['id', 'Nom', 'Prénom', 'Email', 'Téléphone', 'Genre', 'Pays', 'Ville', 'Age', 'Poids', 'Taille', 'Langue', 'Objectif', 'BC'] // order fields in the export
        },
        (err, csv) => {
            downloadCSV(csv, 'Clients'); // download as 'posts.csv` file
        }
    );
};

const ClientsBulkActionButtons = props => (
    <Fragment>
        <NotificationBulkActionButton label="Reset Views" {...props} />
    </Fragment>
);

const ClientFilter = (props: any) => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <SearchInput source="q" resettable />
            <SelectInput
                label="Genre"
                source="gender"
                optionText="name"
                choices={[
                    {
                        id: 'm',
                        name: 'Homme',
                    },
                    {
                        id: 'f',
                        name: 'Femme',
                    },
                ]}
                alwaysOn
            />
            <SelectInput
                label="Objectif"
                source="goal"
                optionText="name"
                choices={[
                    {
                        id: 1,
                        name: 'Sèche',
                    },
                    {
                        id: 2,
                        name: 'Perte de poids',
                    },
                    {
                        id: 3,
                        name: 'Prise de Masse',
                    },
                    {
                        id: 4,
                        name: 'Remise en forme',
                    },
                    {
                        id: 5,
                        name: 'Maintien',
                    },
                ]}
                alwaysOn
            />
            <SelectInput
                label="Langue"
                source="lang"
                optionText="name"
                choices={[
                    {
                        id: 'fr',
                        name: 'FR',
                    },
                    {
                        id: 'en',
                        name: 'EN',
                    },
                    {
                        id: 'es',
                        name: 'ES',
                    },
                ]}
                alwaysOn
            />
            <TextInput source="country" label="Pays" alwaysOn />
            <TextInput source="city" label="Ville" alwaysOn />
            <NumberInput source="weight" label="Poids" />
            <NumberInput source="size" label="Taille" />
            {/* <SearchInput source="lang" label="Langue" /> */}

            {/* (Pays, Ville, Genre, Age, Poids, Taille, Langue, Objectif) */}
            {/* <DateInput source="last_seen_gte" />, */}
            {/* <NullableBooleanInput source="has_ordered" />
        <NullableBooleanInput source="has_newsletter" defaultValue /> */}
            {/* <SegmentInput /> */}
        </Filter>
    );
};

const ClientPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);
const ClientList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<ClientFilter />}
            // undoable={false}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={25}
            pagination={<ClientPagination />}
            bulkActionButtons={<ClientsBulkActionButtons />}
            exporter={exporter}
        >
            {
                <Datagrid optimized rowClick="edit">
                    <CustomerLinkField />
                    <EmailField
                        source="email"
                        label="resources.customers.fields.email"
                    />
                    <TextField source="city" label="Ville" />
                    <TextField source="promoCode" label="Code Promo" />
                    {/* <TextField source="country" label="Pays" /> */}
                    <TextField source="weight" label="Poids" />
                    <TextField source="size" label="Taille" />
                    {/* <TextField source="lang" label="Langue" /> */}
                    <TextField source="gender" label="Genre" />

                    <SelectField
                        label="Objectif"
                        source="goal"
                        choices={[
                            { id: 1, name: 'Sèche' },
                            { id: 2, name: 'Perte de poids' },
                            { id: 3, name: 'Prise de Masse' },
                            { id: 4, name: 'Remise en forme' },
                            { id: 5, name: 'Maintien' },
                        ]}
                    />
                    <NumberField
                        source="baseMetabolism"
                        label="Calories de maintien"
                    />
                    {/*

                    <TextField
                    source="phone"
                    label="resources.customers.fields.phone" />

                    <NumberField
                        source="balance"
                        label="resources.customers.fields.balance"
                        className={classes.balance}
                        options={{ style: 'currency', currency: 'MAD' }}
                    />
                    <ColoredNumberField
                        source="ordersCount"
                        label="resources.customers.fields.commands"
                        className={classes.nb_commands}
                    /> */}
                    <DateField source="created_at" type="date" />
                    <EditButton />
                </Datagrid>
            }
        </List>
    );
};

export default ClientList;
