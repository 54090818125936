import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate, Link } from 'react-admin';
import segments from '../segments/data';
import { FieldProps, Notification } from '../types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const RecipientsField: FC<FieldProps<Notification>> = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return record ? (
        <span className={classes.main}>
            {record.recipients &&
                record.recipients.map(user => {
                    return user && user.name && user.name.full ? (
                  
                           
                            // <Link to={`/users/${user._id}`}>
                             <Chip
                                key={user.id}
                                className={classes.chip}
                                label={user.name.full}
                            />

                        // </Link>
                    ) : null;
                })}
        </span>
    ) : null;
};

RecipientsField.defaultProps = {
    addLabel: true,
    source: 'recipients',
};

export default RecipientsField;
