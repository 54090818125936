import React, { FC, Fragment } from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    useTranslate,
    NumberInput,
    ImageInput,
    required,
    ImageField,
    FormWithRedirect,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    FormDataConsumer,
    BooleanInput,
    SelectInput,
    SelectArrayInput,
    ChipField,
    ReferenceManyField,
    SingleFieldList,
    TextField,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { Meal, FieldProps } from '../types';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name: { width: 544 },
    ingredients: { width: 544 },
    protein: { display: 'inline-block' },
    lipid: { display: 'inline-block', marginLeft: 32 },
    carbohydrates: { display: 'inline-block' },
    lactose: { display: 'inline-block', marginLeft: 32 },
    fruits: { display: 'inline-block' },
    vegetables: { display: 'inline-block', marginLeft: 32 },
};

const MealEdit = (props: any) => {
    return (
        <Edit
            undoable={false}
            title={<MealName />}
            aside={<Aside />}
            component="div"
            {...props}
        >
            <MealForm />
        </Edit>
    );
};

const MealName: FC<FieldProps<Meal>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;


    const TextArrayField = ({ record, source, strArray = [] }: any) =>{ 
 
        return(
        <>
            {record[source] && Array.isArray(record[source])
                ? [...record[source], ...strArray].map((item: any) => (
                      <Chip label={item} key={item} />
                  ))
                : null}
        </>
    )};
    TextArrayField.defaultProps = { addLabel: true };

const useStyles = makeStyles(styles);

const IngredientsArrayField = ({ record, source, strArray = [] }: any) => (
    <>
     <h4>Ingredients :</h4> 
        {record[source] && Array.isArray(record[source])
            ? [...record[source], ...strArray].map((item: any) => (
                  <Chip label={item.name_fr} key={item.id} />
              ))
            : null}
    </>
);
IngredientsArrayField.defaultProps = { addLabel: true };

const EcommerceForm = (props: any) => {
    return (
        <Box display={{ md: 'block', lg: 'block' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput
                            type="number"
                            source="ecommerce.price"
                            inputProps={{
                                step: 1,
                                min: 1,
                            }}
                            defaultValue={0}
                            validation={{ number: true }}
                            validate={requiredValidate}
                            label="resources.meals.fields.ecommerce.price"
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput
                            type="number"
                            source="ecommerce.calories"
                            inputProps={{
                                step: 1,
                                min: 1,
                            }}
                            validation={{ number: true }}
                            validate={requiredValidate}
                            label="resources.meals.fields.ecommerce.calories"
                            fullWidth
                        />
                    </Box>
                </Box>

                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput
                            type="number"
                            source="stock"
                            inputProps={{
                                step: 1,
                                min: 1,
                            }}
                            validation={{ number: true }}
                            validate={requiredValidate}
                            defaultValue={1}
                            fullWidth
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const MealForm = (props: any) => {
    // const translate = useTranslate();
    const classes = useStyles();
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <ImageInput
                                        source="file"
                                        label="Image"
                                        accept="image/*"
                                        multiple={false}
                                    >
                                        <ImageField
                                            source="src"
                                            title="title"
                                        />
                                    </ImageInput>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name_fr"
                                                label="resources.meals.fields.name_fr"
                                                resource="meals"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name_en"
                                                label="resources.meals.fields.name_en"
                                                resource="meals"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name_es"
                                                label="resources.meals.fields.name_es"
                                                resource="meals"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="description_fr"
                                                label="resources.meals.fields.description_fr"
                                                resource="meals"
                                                validate={requiredValidate}
                                                multiline={true}
                                                fullWidth={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="description_en"
                                                label="resources.meals.fields.description_en"
                                                resource="meals"
                                                validate={requiredValidate}
                                                multiline={true}
                                                fullWidth={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="description_es"
                                                label="resources.meals.fields.description_es"
                                                resource="meals"
                                                validate={requiredValidate}
                                                multiline={true}
                                                fullWidth={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                        

                                            <IngredientsArrayField
                                              label="resources.meals.fields.ingredients"
                                             record={formProps.record}
                                                source="ingredients"
                                                formClassName={
                                                    classes.ingredients
                                                }
                                              
                                                fullWidth
                                            />

                                            {/* <ReferenceArrayInput
                                                source="ingredients"
                                                reference="ingredients"
                                                // sort={{
                                                //     field: 'name_fr',
                                                //     order: 'ASC',
                                                // }}
                                                // perPage={100}
                                                formClassName={
                                                    classes.ingredients
                                                }
                                                // validate={requiredValidate}
                                                label="resources.meals.fields.ingredients"
                                                fullWidth
                                            >
                                                <AutocompleteArrayInput
                                                    // source="value"
                                                    optionText="name_fr"
                                                    optionValue="_id"
                                                    // shouldRenderSuggestions={val => {
                                                    //     return (
                                                    //         val.trim().length >
                                                    //         2
                                                    //     );
                                                    // }}
                                                    // inputValueMatcher={(
                                                    //     input,
                                                    //     suggestion,
                                                    //     getOptionText
                                                    // ) =>
                                                    //     input
                                                    //         .toUpperCase()
                                                    //         .trim() ===
                                                    //         suggestion.name_fr ||
                                                    //     input
                                                    //         .toLowerCase()
                                                    //         .trim() ===
                                                    //         getOptionText(
                                                    //             suggestion
                                                    //         )
                                                    //             .toLowerCase()
                                                    //             .trim()
                                                    // }
                                                />
                                            </ReferenceArrayInput> */}
                                        </Box>
                                    </Box>
                                    <Separator />

                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectArrayInput
                                                label="resources.meals.fields.categories"
                                                source="categories"
                                                validate={requiredValidate}
                                                choices={[
                                                    {
                                                        id: 'classique',
                                                        name: 'classique',
                                                    },
                                                    {
                                                        id: 'vegetarien',
                                                        name: 'vegetarien',
                                                    },
                                                    {
                                                        id: 'pesco_vegetarien',
                                                        name:
                                                            'pesco_vegetarien',
                                                    },
                                                    {
                                                        id: 'vegan',
                                                        name: 'vegan',
                                                    },
                                                ]}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectInput
                                                label="resources.meals.fields.type"
                                                source="type"
                                                validate={requiredValidate}
                                                choices={[
                                                    {
                                                        id: 'breakfast',
                                                        name: 'breakfast',
                                                    },
                                                    {
                                                        id: 'snack1',
                                                        name: 'snack1',
                                                    },
                                                    {
                                                        id: 'lunch',
                                                        name: 'lunch',
                                                    },
                                                    {
                                                        id: 'snack2',
                                                        name: 'snack2',
                                                    },
                                                    {
                                                        id: 'dinner',
                                                        name: 'dinner',
                                                    },
                                                ]}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectInput
                                                label="resources.meals.fields.goal"
                                                source="goal"
                                                optionText="name"
                                                validate={requiredValidate}
                                                choices={[
                                                    {
                                                        id: 1,
                                                        name: 'Sèche',
                                                    },
                                                    {
                                                        id: 2,
                                                        name: 'Perte de poids',
                                                    },
                                                    {
                                                        id: 3,
                                                        name: 'Prise de Masse',
                                                    },
                                                    {
                                                        id: 4,
                                                        name: 'Remise en forme',
                                                    },
                                                    {
                                                        id: 5,
                                                        name: 'Maintien',
                                                    },
                                                ]}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    {/*
                                    Ecommerce disabled
                                    <Box
                                        display={{
                                            xs: 'block',
                                            sm: 'flex',
                                        }}
                                    >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <BooleanInput
                                                options={{
                                                    checkedIcon: (
                                                        <MonetizationOnIcon />
                                                    ),
                                                }}
                                                source="ecommerce.enabled"
                                                label="resources.meals.fields.ecommerce.label"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <FormDataConsumer>
                                        {({ formData, ...rest }: any) =>
                                            formData &&
                                            formData.ecommerce &&
                                            formData.ecommerce.enabled ? (
                                                <EcommerceForm />
                                            ) : null
                                        }
                                    </FormDataConsumer> */}
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="meals"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];
const Separator = () => <Box pt="1em" />;
export default MealEdit;
