import React, { useState, useEffect } from 'react';
import { Admin, Resource ,resolveBrowserLocale} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from "./i18n/en";
import frenchMessages from "./i18n/fr";

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
// import { Dashboard } from './dashboard';
import customRoutes from './routes';


import ingredients from './ingredients';
import meals from './meals';
// import users from './users';
import clients from './clients';
import restaurants from './restaurants';
import orders from './orders';
import notifications from './notifications';

import NotFound from './NotFound';

// import visitors from './visitors';
// import orders from './orders_old';
// import products from './products';
// import invoices from './invoices';
// import categories from './categories';
// import reviews from './reviews';

import dataProviderFactory from './dataProvider';
// import fakeServerFactory from './fakeServer';

const messages = {
  fr: frenchMessages,
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale] ? messages[locale] : messages.en,
  resolveBrowserLocale()
);


const App = () => {
    const [dataProvider, setDataProvider] = useState(null);

    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
    
            const  dataProviderInstance = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER
              );
            restoreFetch=dataProviderInstance;
       
            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

         fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Weekly Recipes</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            catchAll={NotFound}
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            // dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >

        {(permissions: string) => {
     
          return [
        
          // Only admin can view resource for admin users
          permissions === "admin" ? (
            <Resource name="ingredients" {...ingredients} />
          ) : null,
          // Only admin can view resource for supervisors users
          permissions === "admin" || permissions === "restaurant"  ? (
            <Resource name="meals" {...meals} />
          ) : null,
          // Only admin can view resource for technicians users
          permissions === "admin" ? (
            <Resource name="users" {...clients}  options={{ label: 'Users' }}/>
          ) : null,
          permissions === "admin"  ? (
            <Resource name="restaurants" {...restaurants} />
          ) : null,
          permissions === "admin" || permissions === "restaurant" ? (
            <Resource name="orders" {...orders} />
          ) : null,

          permissions === "admin" ? (
            <Resource name="notifications" {...notifications} />
          ) : null,

          // permissions === "admin" ? (
          //   <Resource name="users" {...clients}  options={{ label: 'Users' }}/>
          // ) : null,
        ]}}

        </Admin>
    );
};

export default App;
