import React, { FC } from 'react';
import { InputProps } from 'ra-core';
import {
    BooleanField,
    Datagrid,
    DateField,
    TextField,
    ImageField,
    DateInput,
    Filter,
    List,
    NumberInput,
    NumberField,
    SearchInput,
    SelectField,
    EditButton,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import AvatarField from './AvatarField';

import ColoredNumberField from './ColoredNumberField';
import MealThumbnailField from './MealThumbnailField';
import MobileGrid from './MobileGrid';

const MealFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <SearchInput source="protein" /> */}
        <NumberInput source="ecommerce.price" />
    </Filter>
);

const TextArrayField = ({ record, source, strArray = [] }: any) => (
    <>
        {record[source] && Array.isArray(record[source])
            ? [...record[source], ...strArray].map((item: any) => (
                  <Chip label={item} key={item} />
              ))
            : null}
    </>
);
TextArrayField.defaultProps = { addLabel: true };

const useStyles = makeStyles({
    protein: { color: 'purple' },
    lipid: { color: 'purple' },
    carbohydrates: { color: 'purple' },
    lactose: { color: 'purple' },
    fruits: { color: 'purple' },
    vegetables: { color: 'purple' },
});

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const MealList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<MealFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
        >
            {(
                <Datagrid rowClick="edit">
                    <AvatarField
                        size="45"
                        label="resources.meals.fields.image"
                    />
                    <TextField
                        source="name_fr"
                        label="resources.meals.fields.name_fr"
                    />
                    <TextField
                        source="name_en"
                        label="resources.meals.fields.name_en"
                    />
                    <TextField
                        source="name_es"
                        label="resources.meals.fields.name_es"
                    />
                     <NumberField
                        source="programKcal"
                        label="resources.meals.fields.programKcal"
                    />
                    {/* <BooleanField
                        options={{
                            checkedIcon: <MonetizationOnIcon />,
                        }}
                        source="ecommerce.enabled"
                        valueLabelTrue="resources.meals.fields.ecommerce.enabled"
                        valueLabelFalse="resources.meals.fields.ecommerce.disabled"
                        label="resources.meals.fields.ecommerce.label"
                    /> */}
                    {/* <ColoredNumberField
                        source="ecommerce.price"
                        label="resources.meals.fields.ecommerce.price"
                        options={{}}
                    /> */}
                    <SelectField
                        label="resources.meals.fields.goal"
                        source="goal"
                        choices={[
                            { id: 1, name: 'Sèche' },
                            { id: 2, name: 'Perte de poids' },
                            { id: 3, name: 'Prise de Masse' },
                            { id: 4, name: 'Remise en forme' },
                            { id: 5, name: 'Maintien' },
                        ]}
                    />
                    {/* <ColoredNumberField
                        source="stock"
                        label="resources.meals.fields.stock"
                        options={{}}
                    /> */}
                    
                    <SelectField
                        label="resources.meals.fields.type"
                        source="type"
                        choices={[
                            { id: 'breakfast', name: 'Petit déjeuner' },
                            { id: 'snack1', name: 'Collation 1' },
                            { id: 'lunch', name: 'Déjeuner' },
                            { id: 'snack2', name: 'Collation 2' },
                            { id: 'dinner', name: 'Diner' },
                        ]}
                    />
                    <TextArrayField
                        source="categories"
                        label="resources.meals.fields.categories"
                    />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
};

export default MealList;
