import NotificationIcon from './NotificationIcon';

import NotificationList from './NotificationList';
import NotificationCreate from './NotificationCreate';
import NotificationShow from './NotificationShow';

export default {
    list: NotificationList,
    // create: NotificationCreate,
    // // edit: NotificationEdit,
    // show: NotificationShow,
    icon: NotificationIcon,
};
