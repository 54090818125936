import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    usePermissions,
} from 'react-admin';

import ingredients from '../ingredients';
import meals from '../meals';
import clients from '../clients';
import restaurants from '../restaurants';
// import orders from '../orders';
import notifications from '../notifications';
// import visitors from '../visitors';
import orders from '../orders';
// import invoices from '../invoices';
// import products from '../products';
// import categories from '../categories';
// import reviews from '../reviews';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import color from '@material-ui/core/colors/amber';
import { makeStyles } from '@material-ui/core/styles';
import { lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    menuItem: {
        active: { fontWeight: 'bold', color: theme.palette.secondary.main },
    },
    icon: {
        color: theme.palette.secondary.main,
    },
}));

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const { permissions } = usePermissions();
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    });
    const classes = useStyles();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            {/* only for admin */}
            {permissions === 'admin' && (
                <MenuItemLink
                    className={classes.menuItem}
                    to={`/ingredients`}
                    primaryText={translate(`resources.ingredients.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ingredients.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            <MenuItemLink
                className={classes.menuItem}
                to={`/meals`}
                primaryText={translate(`resources.meals.name`, {
                    smart_count: 2,
                })}
                leftIcon={<meals.icon className={classes.icon} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {/* only for admin */}
            {permissions === 'admin' && (
                <MenuItemLink
                    className={classes.menuItem}
                    to={`/users`}
                    primaryText={translate(`resources.clients.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<clients.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {/* only for admin */}
            {permissions === 'admin' && (
                <MenuItemLink
                    className={classes.menuItem}
                    to={`/restaurants`}
                    primaryText={translate(`resources.restaurants.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<restaurants.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            <MenuItemLink
                className={classes.menuItem}
                to={`/orders`}
                primaryText={translate(`resources.orders.name`, {
                    smart_count: 2,
                })}
                leftIcon={<orders.icon className={classes.icon} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {/* only for admin */}
            {permissions === 'admin' && (
                <MenuItemLink
                    className={classes.menuItem}
                    to={`/notifications`}
                    primaryText={translate(`resources.notifications.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<notifications.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
