import React from 'react';
import {
    NumberField,
    TextField,
    DateField,
    useTranslate,
    useGetList,
    linkToRecord,
} from 'react-admin';
import PropTypes from 'prop-types';
import {
    Tooltip,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Avatar,
    IconButton,
    Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ContentCreate from '@material-ui/icons/Create';
import order from '../orders';
import review from '../reviews';
import { makeStyles } from '@material-ui/core/styles';

import ProductReferenceField from '../products/ProductReferenceField';
import StarRatingField from '../reviews/StarRatingField';
import IngredientThumbnailField from './IngredientThumbnailField';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    card: {
        marginBottom:20,
    },
}));

const Aside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

const EventList = ({ record, basePath }) => {
    const classes = useAsideStyles();
    const translate = useTranslate();



    return (
        <>
            <Box m="0 0 1em 1em">
            <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.ingredients.fields.image')}
                        </Typography>
                        <Box display="flex">
                            {/* <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box flexGrow={1}> */}
                                       
                                    <IngredientThumbnailField  record={record}  thumbnail={false}/>
                                    {/* </Box>
                                </Box>
                            </Box> */}
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.ingredients.fields.history')}
                        </Typography>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                     <Typography>
                                            {translate(
                                                'resources.ingredients.fields.created_at'
                                            )}
                                        </Typography> 
                                         <DateField
                                            record={record}
                                            source="created_at"
                                        /> 
                                    </Box>
                                </Box>
                          
                            </Box>
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.ingredients.fields.updated_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="updated_at"
                                        />
                                    </Box>
                                </Box>
                            
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            
        </>
    );
};



const useEventStyles = makeStyles({
    card: {
        margin: '0 0 1em 1em',
    },
    cardHeader: {
        alignItems: 'flex-start',
    },
    clamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
});

const Order = ({ record, basePath }) => {
    const translate = useTranslate();
    const classes = useEventStyles();
    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Avatar
                        aria-label={translate('resources.commands.name', {
                            smart_count: 1,
                        })}
                        className={classes.avatar}
                    >
                        <order.icon />
                    </Avatar>
                }
               
            />
        </Card>
    );
};

const Review = ({ record, basePath }) => {
    const translate = useTranslate();
    const classes = useEventStyles();
    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Avatar
                        aria-label={translate('resources.reviews.name', {
                            smart_count: 1,
                        })}
                        className={classes.avatar}
                    >
                        <review.icon />
                    </Avatar>
                }
                action={<EditButton record={record} basePath="/reviews" />}
                title={
                    <span>
                        {translate('resources.reviews.relative_to_poster')}{' '}
                        <ProductReferenceField
                            resource="reviews"
                            record={record}
                            basePath={basePath}
                        />
                    </span>
                }
                subheader={
                    <>
                        <Typography variant="body2">{record.date}</Typography>
                        <StarRatingField record={record} />
                        <Typography variant="inherit" className={classes.clamp}>
                            {record.comment}
                        </Typography>
                    </>
                }
            />
        </Card>
    );
};

const EditButton = ({ record, basePath }) => {
    const translate = useTranslate();
    return (
        <Tooltip title={translate('ra.action.edit')}>
            <IconButton
                aria-label={translate('ra.action.edit')}
                component={Link}
                to={linkToRecord(basePath, record && record.id)}
            >
                <ContentCreate />
            </IconButton>
        </Tooltip>
    );
};

export default Aside;
