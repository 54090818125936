import React, { FC } from 'react';
import { InputProps } from 'ra-core';
import {
    BooleanField,
    Datagrid,
    DateField,
    TextField,
    ImageField,
    DateInput,
    Filter,
    List,
    NumberField,
    SearchInput,
    EditButton,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import Chip from "@material-ui/core/Chip";
import AvatarField from './AvatarField';

import ColoredNumberField from './ColoredNumberField';
import IngredientThumbnailField from './IngredientThumbnailField';
import MobileGrid from './MobileGrid';

const IngredientFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <SearchInput source="protein" /> */}
        {/* <DateInput source="created_at" /> */}
    </Filter>
);
/**
 * name_fr
name_en
name_es
kcal
proteins
carbs
lipids
sugar
fiber
alcohol
cholesterol
calcium
iron
sodium
macroType
 */


const useStyles = makeStyles({
    proteins: { color: 'purple' },
    lipids: { color: 'purple' },
    carbs: { color: 'purple' },
    sugar: { color: 'purple' },
    fiber: { color: 'purple' },
    alcohol: { color: 'purple' },
    cholesterol: { color: 'purple' },
    calcium: { color: 'purple' },
    iron: { color: 'purple' },
    sodium: { color: 'purple' },
});

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}


const IngredientList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<IngredientFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
        >
            {(
                <Datagrid  rowClick="edit">

                   <AvatarField  size="45" label="resources.ingredients.fields.image"/>
                    <TextField 
                    source="name_fr"
                    label="resources.ingredients.fields.name_fr"  />

                    <TextField 
                    source="name_en"
                    label="resources.ingredients.fields.name_en"  />

                      <TextField 
                    source="name_es"
                    label="resources.ingredients.fields.name_es"  />

                    <NumberField
                        source="proteins"
                        label="resources.ingredients.fields.proteins"
                        className={classes.proteins}
                    />
                    <NumberField
                        source="lipids"
                        label="resources.ingredients.fields.lipids"
                        className={classes.lipids}
                    />
                    <NumberField
                        source="carbs"
                        label="resources.ingredients.fields.carbs"
                        className={classes.carbs}
                    />

                     {/* <TextField
                        source="macroType"
                        label="resources.ingredients.fields.macroType"
                    /> */}
                    <ColoredNumberField
                        source="kcal"
                        options={{  }}
                    />
                    <EditButton/>
                </Datagrid>
            )}
        </List>
    );
};

export default IngredientList;
