import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps, Order } from '../types';
import Chip from "@material-ui/core/Chip";
const useStyles = makeStyles({
    root: { width: 25, maxWidth: 25, maxHeight: 25 },
});

const OrderThumbnailField: FC<any> = ({ record ,thumbnail=true}) => {
    // const rootClasses=useStyles();
    // const propsClasses=makeStyles({root:style});

     const classes =useStyles();

     const imageUrl=record&&record.meal?process.env.REACT_APP_PUBLIC_URL+record.meal.imageUrl:'';
    return record&&imageUrl ? (
        <img src={imageUrl} className={thumbnail?classes.root:''} alt="" />
    ) :(<Chip variant="outlined" size="small" label="No Image" />);
};

export default OrderThumbnailField;
