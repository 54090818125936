import React, { FC, createRef } from "react";
import { InputProps } from "ra-core";
import { Field, useForm } from "react-final-form";
import PropTypes from "prop-types";
//lealeft maps
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});


const refmarker = createRef<Marker>();

interface Props extends Omit<InputProps, "source"> {
  source?: string;
}

const MapField: FC<Props> = ({ ...rest }) => {
  const form = useForm();
  const initialPosition=[34, -6];
  const record = rest.record;

  const lat =
    record &&
    record.location &&
    record.location.coordinates &&
    record.location.coordinates[1]
      ? record.location.coordinates[1]
      : initialPosition[0];
  const lng =
    record &&
    record.location &&
    record.location.coordinates &&
    record.location.coordinates[0]
      ? record.location.coordinates[0]
      : initialPosition[1];

  const updatePosition = args => {
    try {
      const marker = refmarker.current;
      if (marker != null) {
        const coords = marker.leafletElement.getLatLng();
        // record.coordinates=[coords['lat'],coords['lng']];
        form.change("lat", coords["lat"]);
        form.change("lng", coords["lng"]);
      }
    } catch (error) {
      console.error("updatePosition error");
      console.error(error)
    }
  };

  return (
    <>
      <LeafletMap
        key="#78_u3421"
        style={{ height: 200, width: 544 }}
        center={initialPosition}
        zoom={6}
        maxZoom={10}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <TileLayer
          key="#78_u32"
          url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          key="#78_u"
          draggable="true"
          ref={refmarker}
          onDragend={updatePosition}
          position={[lat, lng]}
          //     onClick={() => {
          //       alert("park");
          //     }}
        />
      </LeafletMap>
      <span key="#78w_u">
        <Field
          label="Edit"
          key="#78_u38772"
          name="lat"
          component="input"
          type="text"
          initialValue={lat}
        />
        &nbsp;
        <Field
          label="Edit"
          key="#78_u38829"
          name="lng"
          component="input"
          type="number"
          initialValue={lng}
        />
      </span>
    </>
  );
};

MapField.propTypes = {
  record: PropTypes.any
};

MapField.defaultProps = {
  style: { height: 200, width: 544 },
  label: "Map",
  source: "location.coordinates",
  resource: "recyclingcenters"
};

export default MapField;
