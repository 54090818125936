import OrderIcon from './OrderIcon';

import OrderList from './OrderList';
import OrderCreate from './OrderCreate';
import OrderEdit from './OrderEdit';

export default {
    list: OrderList,
    // create: OrderCreate,
    edit: OrderEdit,
    icon: OrderIcon,
};
