import MealIcon from './MealIcon';

import MealList from './MealList';
import MealCreate from './MealCreate';
import MealEdit from './MealEdit';


export default {
    list: MealList,
    create: MealCreate,
    edit: MealEdit,
    icon: MealIcon,
};
