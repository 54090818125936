import React, { FC } from 'react';
import { InputProps } from 'ra-core';
import {
    BooleanField,
    Datagrid,
    DateField,
    TextField,
    ImageField,
    DateInput,
    Filter,
    List,
    NumberField,
    SearchInput,
    EditButton,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import AvatarField from './AvatarField';

import ColoredNumberField from './ColoredNumberField';
import RestaurantThumbnailField from './RestaurantThumbnailField';
import MobileGrid from './MobileGrid';

const RestaurantFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <SearchInput source="protein" /> */}
        {/* <DateInput source="created_at" /> */}
    </Filter>
);

const useStyles = makeStyles({

});

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const RestaurantList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<RestaurantFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
        >
                <Datagrid rowClick="edit">
                    <AvatarField
                        size="45"
                        label="resources.restaurants.fields.image"
                    />
             
                    {/* <RestaurantThumbnailField label="resources.restaurants.fields.name"/> */}
                    <TextField
                        source="first_name"
                        label="resources.restaurants.fields.first_name"
                    />
                     <TextField
                        source="last_name"
                        label="resources.restaurants.fields.last_name"
                    />
                    
                    <TextField
                        source="email"
                        label="resources.restaurants.fields.email"
                    />
                    <TextField
                        source="phone"
                        label="resources.restaurants.fields.phone"
                    />
                     <TextField
                        source="name"
                        label="resources.restaurants.fields.name"
                    />
                    <TextField
                        source="address1"
                        label="resources.restaurants.fields.address1"
                    />
                    
                     <ColoredNumberField source="revenue" options={{}}    label="resources.restaurants.fields.revenue"/>
            
                    <ColoredNumberField source="ordersCount" options={{}}  label="resources.restaurants.fields.ordres"/>
                   
                    <EditButton />
                </Datagrid>
    
        </List>
    );
};

export default RestaurantList;