// // in ./NotificationBulkActionButton.js
// import React, { Fragment, useState } from 'react';
// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
// import {
//     Button,
//     Confirm,
//     useUpdateMany,
//     useCreate,
//     useRefresh,
//     useNotify,
//     useUnselectAll,
// } from 'react-admin';

// const NotificationBulkActionButton = (props) => {
//     const { resource, selectedIds } = props
//     const [open, setOpen] = useState(false);
//     const refresh = useRefresh();
//     const notify = useNotify();
//     const unselectAll = useUnselectAll();
//     // const [updateMany, { loading }] = useUpdateMany(
//     //     'posts',
//     //     selectedIds,
//     //     { views: 0 },
//     //     {
//     //         onSuccess: () => {
//     //             refresh();
//     //             notify('Notification  Envoyer');
//     //             unselectAll(resource);
//     //         },
//     //         onFailure: error => notify('Error: posts not updated', 'warning'),
//     //     }
//     // );
//     const [updateMany, { loading }] = useUpdateMany(
//         'posts',
//         selectedIds,
//         { views: 0 },
//         {
//             onSuccess: () => {
//                 refresh();
//                 notify('Notification  Envoyer');
//                 unselectAll(resource);
//             },
//             onFailure: error => notify('Error: posts not updated', 'warning'),
//         }
//     );
//     const handleClick = () => setOpen(true);
//     const handleDialogClose = () => setOpen(false);

//     const handleConfirm = () => {
//         updateMany();
//         setOpen(false);
//     };

//     return (
//         <Fragment>
//             <Button label="Notifier" onClick={handleClick} />
//             {/* <Confirm
//                 isOpen={open}
//                 loading={loading}
//                 title="Update View Count"
//                 content="Are you sure you want to reset the views for these items?"
//                 onConfirm={handleConfirm}
//                 onClose={handleDialogClose}
//             /> */}
//         </Fragment>
//     );
// }

// export default NotificationBulkActionButton;

import React, { useState, Fragment, useCallback } from 'react';
import { useForm } from 'react-final-form';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    NumberInput,
    TextInput,
    required,
    CREATE,
    Create,
    Toolbar,
    useCreate,
    useRedirect,
    useNotify,
} from 'react-admin';
import SendIcon from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Link } from 'react-router-dom';
import dataProvider from '../dataProvider';
// import Button from '@material-ui/core/Button';

import { useFormState } from 'react-final-form';

let userIds = [];
const SaveWithNoteButton = props => {
 
    const {handleCloseDialog} = props;
    const [create] = useCreate('notifications');
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath, redirect } = props;
    // const [showDialog, setShowDialog] = React.useState(false);
    const formState = useFormState();
    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }
        create(
            {
                payload: { data: { ...formState.values, recipients: userIds } },
            },
            {
                onSuccess: ({ data: newRecord }) => {
                    notify('Notification Envoyée', 'info', {
                        smart_count: 1,
                    });
                    handleCloseDialog();
                    redirectTo(redirect, basePath, newRecord.id, newRecord);
                },
                onFailure: error => { 
                    handleCloseDialog();
                    notify('Error: Notification non envoyé', 'warning')},
            }
        );
    }, [
        formState.valid,
        formState.values,
        create,
        notify,
        redirectTo,
        redirect,
        basePath,
    ]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};


export default props => {
    const { resource, selectedIds } = props;
    userIds = selectedIds;
    const [showDialog, setShowDialog] = React.useState(false);
    const [, setData] = useState({});

    const handleOpenDialog = () => setShowDialog(true);

    const handleCloseDialog = () => setShowDialog(false);

    // const handleSave = () => {
    //     submitQC();
    //     setShowDialog(false);
    // };

    const PostCreateToolbar = props => (
        <Toolbar {...props}>
    
            <SaveWithNoteButton
                handleCloseDialog ={handleCloseDialog}
                label="Envoyer"
                // redirect="list"
                submitOnEnter={false}
                variant="text"
            />
            <Button label="Annuler" onClick={handleCloseDialog}>
                <IconCancel />
            </Button>
        </Toolbar>
    );


    const NotifForm = ({ formData, ...rest }) => {
        const form = useForm();

    

        return (
            <Fragment>
                <TextInput
                    fullWidth={true}
                    validation={[required()]}
                    multiline
                    source="message"
                    label="Contenu de la notification"
                />
            </Fragment>
        );
    };





    return (
        <>
            <Button onClick={handleOpenDialog} label="Envoyer Notification">
                <SendIcon />
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                // onClose={handleCloseDialog}
                aria-label="Nouvelle Notification"
            >
                <DialogTitle>Nouvelle Notification</DialogTitle>
                <DialogContent>
                    <Create {...props}>
                        <SimpleForm
                            submitOnEnter={false}
                            resource="notifications"
                            // save={handleSubmit}
                            toolbar={<PostCreateToolbar />}
                        >
                            <NotifForm />
                        </SimpleForm>
                    </Create>
                </DialogContent>
                <DialogActions>
                  
                </DialogActions>
            </Dialog>
        </>
    );
};
