import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps, Meal } from '../types';
import Chip from "@material-ui/core/Chip";
const useStyles = makeStyles({
    root: { width: 25, maxWidth: 25, maxHeight: 25 },
});

const MealThumbnailField: FC<any> = ({ record ,thumbnail=true}) => {
    // const rootClasses=useStyles();
    // const propsClasses=makeStyles({root:style});

     const classes =useStyles();

     const imageUrl=record?process.env.REACT_APP_PUBLIC_URL+record.imageUrl:'';
    return record ? (
        <img src={imageUrl} className={thumbnail?classes.root:''} alt="" />
    ) :(<Chip variant="outlined" size="small" label="No Image" />);
};

export default MealThumbnailField;
