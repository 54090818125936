import ClientIcon from './ClientIcon';

import ClientList from './ClientList';
import ClientCreate from './ClientCreate';
import ClientEdit from './ClientEdit';

export default {
    list: ClientList,
    // create: ClientCreate,
    edit: ClientEdit,
    icon: ClientIcon,
};
