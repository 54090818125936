import IngredientIcon from './IngredientIcon';

import IngredientList from './IngredientList';
import IngredientCreate from './IngredientCreate';
import IngredientEdit from './IngredientEdit';

export default {
    list: IngredientList,
    create: IngredientCreate,
    edit: IngredientEdit,
    icon: IngredientIcon,
};
