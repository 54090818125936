import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    NumberInput,
    useTranslate,
    SelectInput,
    DateInput,
    required,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Styles } from '@material-ui/styles/withStyles';

/**
 name_fr
name_en
name_es
kcal
macroType

proteins
carbs

lipids
sugar

fiber
alcohol

cholesterol
calcium

iron
sodium

 */
export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name_fr: { width: 544 },
    name_en: { width: 544 },
    name_es: { width: 544 },
    kcal: { display: 'inline-block' },
    kj:{ display: 'inline-block', marginLeft: 32 },
    macroType: { width: 544 },
    maxQuantity: { width: 544 },


    proteins: { display: 'inline-block' },
    carbs: { display: 'inline-block', marginLeft: 32 },

    lipids: { display: 'inline-block'},
    sugar: { display: 'inline-block' , marginLeft: 32  },


    fiber: { display: 'inline-block' },
    alcohol: { display: 'inline-block', marginLeft: 32 },

    cholesterol: { display: 'inline-block' },
    calcium: { display: 'inline-block', marginLeft: 32 },

    iron: { display: 'inline-block' },
    sodium: { display: 'inline-block', marginLeft: 32 }


};

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.customers.errors.password_mismatch',
        ];
    }

    return errors;
};

const useStyles = makeStyles(styles);

const IngredientCreate = (props: any) => {
    const classes = useStyles();

    return (
        <Create {...props}>
            <SimpleForm validate={validatePasswords}>
                {/* <SectionTitle label="resources.ingredients.fields.image" /> */}
                <ImageInput
                    source="file"
                    label="Image"
                    labelSingle="resources.ingredients.image.upload_single"
                    accept="image/*"
                    multiple={false}
                    formClassName={classes.image}
                >
                    <ImageField accept="image/*" source="src" title="title" />
                </ImageInput>



                <Separator />
                <TextInput
                    autoFocus
                    source="name_fr"
                    fullWidth={true}
                    formClassName={classes.name_fr}
                    validate={requiredValidate}
                />
                  <Separator />
                <TextInput
                    source="name_en"
                    fullWidth={true}
                    formClassName={classes.name_en}
                    validate={requiredValidate}
                />
                  <Separator />
                <TextInput
                    source="name_es"
                    fullWidth={true}
                    formClassName={classes.name_es}
                    validate={requiredValidate}
                />
                <Separator />
                <NumberInput
                    source="kcal"
                    fullWidth={true}
                    formClassName={classes.kcal}
                    validate={requiredValidate}
                />
                <NumberInput
                    source="kj"
                    fullWidth={true}
                    formClassName={classes.kj}
                    validate={requiredValidate}
                />
                 <Separator />
                <NumberInput
                    source="maxQuantity"
                    fullWidth={true}
                    formClassName={classes.maxQuantity}
                    validate={requiredValidate}
                />
                <Separator />
                {/* <Separator />
                <SelectInput
                    label="resources.ingredients.fields.macroType"
                    source="macroType"
                    formClassName={classes.macroType}
                    fullWidth={true}
                    validate={requiredValidate}
                    choices={[
                        {
                            id: 'protein',
                            name: 'Protein'
                        },
                        {
                            id: 'carb',
                            name: 'Carb',
                        },
                        {
                            id: 'lipid',
                            name: 'lipid',
                        },
                        {
                            id: 'veg',
                            name: 'Veg',
                        },
                        {
                            id: 'lact',
                            name: 'Lact',
                        },
                        {
                            id: 'fruit',
                            name: 'Fruit',
                        },
                    ]}
                /> */}
                <Separator />

                <NumberInput
                    type="number"
                    source="proteins"
                    formClassName={classes.proteins}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 0,
                    }}
                />

<NumberInput
                    type="number"
                    source="carbs"
                    formClassName={classes.carbs}
                    step={1}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 0,
                    }}

                />
                        <Separator />

                <NumberInput
                    type="number"
                    source="lipids"
                    formClassName={classes.lipids}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                />
              
                {/* <NumberInput
                    type="number"
                    source="sugar"
                    formClassName={classes.sugar}
                    step={1}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                />
                <Separator />
                <NumberInput
                    type="number"
                    source="fiber"
                    formClassName={classes.fiber}
                    step={1}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                />
                <NumberInput
                    type="number"
                    source="alcohol"
                    formClassName={classes.alcohol}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                />
                <Separator />
                <NumberInput
                    type="number"
                    source="cholesterol"
                    formClassName={classes.cholesterol}
                    step={1}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                />
                <NumberInput
                    type="number"
                    source="calcium"
                    formClassName={classes.calcium}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                />
                     <Separator />
                <NumberInput
                    type="number"
                    source="iron"
                    formClassName={classes.iron}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                />
                <NumberInput
                    type="number"
                    source="sodium"
                    formClassName={classes.calcium}
                    validation={{ number: true }}
                    validate={requiredValidate}
                    defaultValue={0}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                /> */}
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default IngredientCreate;
