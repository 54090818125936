import frenchMessages from 'ra-language-french';

export default {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Pass',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    image: {
        upload_several:
            'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
        upload_single:
            'Glissez une photo à uploader ou cliquez pour la sélectionner.',
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            monthly_revenue: 'CA à 30 jours',
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items:
                    'par %{customer_name}, un poster |||| par %{customer_name}, %{nb_items} posters',
            },
            welcome: {
                title: 'Bienvenue sur la démo de react-admin',
                subtitle:
                    "Ceci est le back-office d'un magasin de posters imaginaire. N'hésitez pas à explorer et à modifier les données. La démo s'exécute en local dans votre navigateur, et se remet à zéro chaque fois que vous rechargez la page.",
                aor_button: 'Site web de react-admin',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            catalog: 'Catalogue',
            customers: 'Clients',
        },
    },
    resources: {
        ingredients: {
            name: 'Ingrédient |||| Ingrédients',
            fields: {
//                 name_fr
// // name_en
// // name_es
// // kcal
// // proteins
// // carbs
// // lipids
// // sugar
// // fiber
// // alcohol
// // cholesterol
// // calcium
// // iron
// // sodium
// // macroType
// // ration
                name_fr: 'Nom Fr',
                name_en: 'Nom En',
                name_es: 'Nom Es',
                image:'Image',
                kcal:'Kcal',
                kj:'Kj',
                maxQuantity:'Quantité max',
                proteins: 'Proteins',
                lipids: 'Lipides',
                sugar: 'Sucre',
                fruits: 'Fruits',
                cholesterol:'Cholesterol',
                calcium: 'Calcium',
                alcohol:'Alcohol',
                fiber:'Fiber',
                carbs: 'Carbs',
                sodium: 'Sodium',
                iron: 'Iron',
                macroType:'Macro Type',
                energy: 'Energie',
                history: 'Historique',
                created_at: 'Date de Création',
                updated_at: 'Date de mise à jour',
                
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        meals: {
            name: 'Plat |||| Plats',
            fields: {
                name_fr: 'Nom Fr',
                name_en: 'Nom En',
                name_es: 'Nom Es',
                kcal:'Kcal',
                kj:'Kj',
                image:'Image',
                protein: 'Protein',
                lipid: 'Lipide',
                carbohydrates: 'Carbohydrates',
                lactose: 'Lactose',
                fruits: 'Fruits',
                vegetables: 'Vegetables',
                energy: 'Energie',
                history: 'Historique',
                created_at: 'Date de Création',
                updated_at: 'Date de mise à jour',
                ingredients: 'Ingrédients',
                categories: 'Catégories',
                types:'Repas',
                type:'Repas',
                description_fr: 'Description FR',
                description_en: 'Description EN',
                description_es: 'Description ES',
                stock:'Stock',
                goal:'Objectif',
                category:'Categorie',
                programKcal:'Program Kcal',
                file:'Fichier CSV',
                ecommerce:{
                    label:'E-Commerce',
                    eneabled:'Enabled',
                    disabled:'disabled',
                    price:'Prix',
                    goals:'Objectifs',
                    calories:'Calories',

                }
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        clients: {
            name: 'Client |||| Clients',
        },
        restaurants: {
            name: 'Restaurant |||| Restaurants',
            fields: {
                name: 'Nom',
                email:'Email',
                first_name: 'Prénom du gérant',
                last_name: 'Nom du gérant',
                phone:'Tél',
                description: 'Description',
                created_at: 'Date de Création',
                updated_at: 'Date de mise à jour',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                zip: 'Code postal',
                image:'Image',
                city:'Ville',
                country:'Pays',
                address:'Adresse',
                address1:'Adresse',
                address2:'Adresse 2',
                revenue:'C.A',
                ordres:'Commandes',
                history: 'Historique',
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        orders: {
            name: 'Commande |||| Commandes',
            fields: {
                meal: 'Nom du Plat',
                image:'Image',
                price:'Prix',
                quantity:'Quantité',
                history: 'History',
                created_at: 'Date de Création',
                updated_at: 'Date de mise à jour',
                stock:'Stock',
                total:'Total',
                user:'Client',
                restaurant:'Restaurant',
                status:'Status',
                paid:'Payé',
                ecommerce:{
                    label:'E-Commerce',
                    eneabled:'Enabled',
                    disabled:'disabled',
                    price:'Price',
                    goals:'Goals',
                    calories:'Calories',

                },
            },
        },
        notifications: {
            name: 'Notification |||| Notifications',
            fields: {
                message: 'Message',
                recipients:'Déstinataires',
                history:'Historique',
                created_at: 'Date de Création',
                updated_at: 'Date de mise à jour',
            },
            actions:{
                send:'Envoyer'
            }
        },
        customers: {
            name: 'Client |||| Clients',
            fields: {
                address: 'Rue',
                birthday: 'Anniversaire',
                city: 'Ville',
                commands: 'Commandes',
                first_name: 'Prénom',
                first_seen: 'Première visite',
                groups: 'Segments',
                has_newsletter: 'Abonné à la newsletter',
                has_ordered: 'A commandé',
                last_name: 'Nom',
                last_seen: 'Vu le',
                last_seen_gte: 'Vu depuis',
                latest_purchase: 'Dernier achat',
                name: 'Nom',
                firstName: 'Prénom',
                size:'Taille',
                weight:'Poids',
                birthdate:'Date de Naissance',
                total_spent: 'Dépenses',
                zipcode: 'Code postal',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                email:'Email',
                phone:'Téléphone',
                balance:'Solde',
                created_at: 'Date de Création',
                updated_at: 'Date de mise à jour',
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        commands: {
            name: 'Commande |||| Commandes',
            amount: '1 commande |||| %{smart_count} commandes',
            title: 'Commande n°%{reference}',
            fields: {
                basket: {
                    delivery: 'Frais de livraison',
                    reference: 'Référence',
                    quantity: 'Quantité',
                    sum: 'Sous-total',
                    tax_rate: 'TVA',
                    total: 'Total',
                    unit_price: 'P.U.',
                },
                customer_id: 'Client',
                date_gte: 'Emises depuis',
                date_lte: 'Emises avant',
                nb_items: 'Nb articles',
                reference: 'Référence',
                returned: 'Annulée',
                status: 'Etat',
                total_gte: 'Montant minimum',
            },
        },
        invoices: {
            name: 'Facture |||| Factures',
            fields: {
                id: 'Numéro',
                date: 'Date de facture',
                customer_id: 'Client',
                command_id: 'Commande',
                date_gte: 'Emises depuis',
                date_lte: 'Emises avant',
                address: 'Adresse',
                total_ex_taxes: 'Montant HT',
                delivery_fees: 'Frais de livraison',
                taxes: 'TVA',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Catégorie',
                height_gte: 'Hauteur mini',
                height_lte: 'Hauteur maxi',
                height: 'Hauteur',
                image: 'Photo',
                price: 'Prix',
                reference: 'Référence',
                stock_lte: 'Stock faible',
                stock: 'Stock',
                thumbnail: 'Aperçu',
                width_gte: 'Largeur mini',
                width_lte: 'Margeur maxi',
                width: 'Largeur',
            },
            tabs: {
                image: 'Image',
                details: 'Détails',
                description: 'Description',
                reviews: 'Commentaires',
            },
        },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                name: 'Nom',
                products: 'Produits',
            },
        },
        reviews: {
            name: 'Commentaire |||| Commentaires',
            amount: '1 commentaire |||| %{smart_count} commentaires',
            relative_to_poster: 'Commentaire sur',
            detail: 'Détail du commentaire',
            fields: {
                customer_id: 'Client',
                command_id: 'Commande',
                product_id: 'Produit',
                date_gte: 'Publié depuis',
                date_lte: 'Publié avant',
                date: 'Date',
                comment: 'Texte',
                status: 'Statut',
                rating: 'Classement',
            },
            action: {
                accept: 'Accepter',
                reject: 'Rejeter',
            },
            notification: {
                approved_success: 'Commentaire approuvé',
                approved_error: 'Erreur: Commentaire non approuvé',
                rejected_success: 'Commentaire rejeté',
                rejected_error: 'Erreur: Commentaire non rejeté',
            },
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Clients',
                name: 'Nom',
            },
            data: {
                compulsive: 'Compulsif',
                collector: 'Collectionneur',
                ordered_once: 'A commandé',
                regular: 'Régulier',
                returns: 'A renvoyé',
                reviewer: 'Commentateur',
            },
        },
    },
};
