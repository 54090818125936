import React, { FC } from 'react';
import { InputProps } from 'ra-core';
import {
    BooleanField,
    Datagrid,
    DateField,
    TextField,
    ImageField,
    DateInput,
    Filter,
    List,
    NumberField,
    SearchInput,
    EditButton,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import AvatarField from './AvatarField';

import ColoredNumberField from './ColoredNumberField';
import OrderThumbnailField from './OrderThumbnailField';
import MobileGrid from './MobileGrid';

const OrderFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <SearchInput source="protein" /> */}
        {/* <DateInput source="created_at" /> */}
    </Filter>
);

const useStyles = makeStyles({
    price: { color: 'purple' },
    quantity: { color: 'purple' },
    totalPrice: { color: 'totalPrice' },
});

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const OrderList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<OrderFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
        >
           
                <Datagrid rowClick="edit">
                    <AvatarField
                        size="45"
                        label="resources.orders.fields.image"
                    />
                    <TextField
                        source="meal.name"
                        label="resources.orders.fields.meal"
                    />
                    <NumberField
                        source="price"
                        label="resources.orders.fields.price"
                        className={classes.price}
                    />
                    <NumberField
                        source="quantity"
                        label="resources.orders.fields.quantity"
                        className={classes.quantity}
                    />
                    <NumberField
                        source="totalPrice"
                        label="resources.orders.fields.total"
                        className={classes.totalPrice}
                    />
                    <TextField
                        source="user.name.full"
                        label="resources.orders.fields.user"
                    />
                    <TextField
                        source="user.restaurant.name"
                        label="resources.orders.fields.restaurant"
                    />
                     <TextField
                        source="status"
                        label="resources.orders.fields.status"
                    />
                    <EditButton />
                </Datagrid>
          
        </List>
    );
};

export default OrderList;
