import React, { FC } from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    useTranslate,
    NumberInput,
    ImageInput,
    SelectInput,
    required,
    ImageField,
    FormWithRedirect,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { Ingredient, FieldProps } from '../types';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name: { width: 544 },
    protein: { display: 'inline-block' },
    lipid: { display: 'inline-block', marginLeft: 32 },
    carbohydrates: { display: 'inline-block' },
    lactose: { display: 'inline-block', marginLeft: 32 },
    fruits: { display: 'inline-block' },
    vegetables: { display: 'inline-block', marginLeft: 32 },
    majority: { width: 544 },
    maxQuantity:{ width: 544 },
};

const IngredientEdit = (props: any) => {
    return (
        <Edit
            undoable={false}
            title={<IngredientName />}
            aside={<Aside />}
            component="div"
            {...props}
        >
            <IngredientForm />
        </Edit>
    );
};

const IngredientName: FC<FieldProps<Ingredient>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const useStyles = makeStyles(styles);
const IngredientForm = (props: any) => {
    // const translate = useTranslate();
    const classes = useStyles();
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <IngredientThumbnailField  record={formProps.record}/> */}
                                    <ImageInput
                                        source="file"
                                        label="Image"
                                        accept="image/*"
                                        multiple={false}
                                    >
                                        <ImageField
                                            source="src"
                                            title="title"
                                        />
                                    </ImageInput>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name_fr"
                                                resource="ingredients"
                                                label="resources.ingredients.fields.name_fr"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                             <TextInput
                                                source="name_en"
                                                resource="ingredients"
                                                label="resources.ingredients.fields.name_en"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                             <TextInput
                                                source="name_es"
                                                resource="ingredients"
                                                label="resources.ingredients.fields.name_es"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                              <SelectInput
                                        label="resources.ingredients.fields.macroType"
                                        source="macroType"
                                        fullWidth={true}
                                        validate={requiredValidate}
                                        choices={[
                                            {
                                                id: 'protein',
                                                name: 'Protein'
                                            },
                                            {
                                                id: 'carb',
                                                name: 'Carb',
                                            },
                                            {
                                                id: 'lipid',
                                                name: 'lipid',
                                            },
                                            {
                                                id: 'veg',
                                                name: 'Veg',
                                            },
                                            {
                                                id: 'lact',
                                                name: 'Lact',
                                            },
                                            {
                                                id: 'fruit',
                                                name: 'Fruit',
                                            },
                                        ]}
                                    />
                                        </Box>
                                    </Box> */}

                                  


                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="proteins"
                                                label="resources.ingredients.fields.proteins"
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="carbs"
                                                label="resources.ingredients.fields.carbs"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="lipids"
                                                label="resources.ingredients.fields.lipids"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        {/* <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="sugar"
                                                label="resources.ingredients.fields.sugar"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box> */}
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="maxQuantity"
                                                label="resources.ingredients.fields.maxQuantity"
                                                validation={{ number: true }}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

{/* 
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                             <NumberInput
                                                type="number"
                                                source="fiber"
                                                label="resources.ingredients.fields.fiber"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="alcohol"
                                                label="resources.ingredients.fields.alcohol"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                             <NumberInput
                                                type="number"
                                                source="cholesterol"
                                                label="resources.ingredients.fields.cholesterol"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="calcium"
                                                label="resources.ingredients.fields.calcium"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                             <NumberInput
                                                type="number"
                                                source="iron"
                                                label="resources.ingredients.fields.iron"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="sodium"
                                                label="resources.ingredients.fields.sodium"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box> */}

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            {/* <DateInput
                                                source="created_at"
                                                resource="ingredients"
                                                fullWidth
                                            /> */}
                                        </Box>
                                        <Box
                                            flex={2}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        />
                                    </Box>
                                </Box>
                      
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="ingredients"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];
const Separator = () => <Box pt="1em" />;
export default IngredientEdit;
