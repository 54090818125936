import React, { FC } from 'react';
import {
    Edit,
    TextInput,
    TextField,
    Toolbar,
    useTranslate,
    NumberInput,
    BooleanInput,
    SelectField,
    SelectInput,
    required,
    ImageField,
    FormWithRedirect,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { Order, FieldProps } from '../types';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name: { width: 544 },
    protein: { display: 'inline-block' },
    lipid: { display: 'inline-block', marginLeft: 32 },
    carbohydrates: { display: 'inline-block' },
    lactose: { display: 'inline-block', marginLeft: 32 },
    fruits: { display: 'inline-block' },
    vegetables: { display: 'inline-block', marginLeft: 32 },
};

const OrderEdit = (props: any) => {
    return (
        <Edit
            undoable={false}
            title={<OrderName />}
            aside={<Aside />}
            component="div"
            {...props}
        >
            <OrderForm />
        </Edit>
    );
};

const OrderName: FC<FieldProps<Order>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const useStyles = makeStyles(styles);
const OrderForm = (props: any) => {
    // const translate = useTranslate();
    const classes = useStyles();
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            
                                            <TextInput
                                                source="meal.name"
                                                label="resources.orders.fields.meal"
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="user.name.full"
                                                label="resources.orders.fields.user"
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="price"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1
                                                  }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                              
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="quantity"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1
                                                  }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                               
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                type="number"
                                                source="totalPrice"
                                                inputProps={{
                                                    step: 1,
                                                    min: 1
                                                  }}
                                                validation={{ number: true }}
                                                validate={requiredValidate}
                                               
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <BooleanInput
                                                options={{
                                                    checkedIcon: (
                                                        <MonetizationOnIcon />
                                                    ),
                                                }}
                                                source="paid"
                                                label="resources.orders.fields.paid"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                              {/* <TextInput
                                                source="status"
                                                label="resources.orders.fields.status"
                                                disabled
                                                fullWidth
                                            /> */}

                                            <SelectInput
                                                source="status"
                                                choices={[
                                                    {
                                                        id: 'pending',
                                                        name: 'Pending',
                                                    },
                                                    {
                                                        id: 'accepted',
                                                        name: 'Accepted',
                                                    },
                                                    {
                                                        id: 'delivered',
                                                        name: 'Delivered',
                                                    },
                                                    {
                                                        id: 'cancelled',
                                                        name: 'Cancelled',
                                                    },
                                                ]}
                                                optionText="name"
                                                optionValue="id"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                             
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="orders"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];
const Separator = () => <Box pt="1em" />;
export default OrderEdit;
