import React, { FC } from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    useTranslate,
    NumberInput,
    ImageInput,
    required,
    ImageField,
    FormWithRedirect,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

import MapField from './MapField';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { Restaurant, FieldProps } from '../types';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name: { width: 544 },
    protein: { display: 'inline-block' },
    lipid: { display: 'inline-block', marginLeft: 32 },
    carbohydrates: { display: 'inline-block' },
    lactose: { display: 'inline-block', marginLeft: 32 },
    fruits: { display: 'inline-block' },
    vegetables: { display: 'inline-block', marginLeft: 32 },
};

const RestaurantEdit = (props: any) => {
    return (
        <Edit
            undoable={false}
            title={<RestaurantName />}
            aside={<Aside />}
            component="div"
            {...props}
        >
            <RestaurantForm />
        </Edit>
    );
};

const RestaurantName: FC<FieldProps<Restaurant>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const useStyles = makeStyles(styles);
const RestaurantForm = (props: any) => {
    // const translate = useTranslate();
    const classes = useStyles();
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <RestaurantThumbnailField  record={formProps.record}/> */}
                                    <ImageInput
                                        source="file"
                                        label="Image"
                                        accept="image/*"
                                        multiple={false}
                                    >
                                        <ImageField
                                            source="src"
                                            title="title"
                                        />
                                    </ImageInput>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name"
                                                resource="restaurants"
                                                label="resources.restaurants.fields.name"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="email"
                                                resource="restaurants"
                                                label="resources.restaurants.fields.email"
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                         <MapField record={formProps.record}  source="location.coordinates"/>
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="description"
                                                resource="restaurants"
                                                label="resources.restaurants.fields.description"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="first_name"
                                                validate={requiredValidate}
                                                label="resources.restaurants.fields.first_name"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="last_name"
                                                validate={requiredValidate}
                                                label="resources.restaurants.fields.last_name"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="phone"
                                                validate={requiredValidate}
                                                label="resources.restaurants.fields.phone"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="password"
                                                // validate={requiredValidate}
                                                label="resources.restaurants.fields.password"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    
                        
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="restaurants"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];
const Separator = () => <Box pt="1em" />;
export default RestaurantEdit;
