import React, { Fragment } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    NumberInput,
    useTranslate,
    Toolbar,
    required,
    AutocompleteArrayInput,
    FormWithRedirect,
    ReferenceArrayInput,
    FormDataConsumer,
    BooleanInput,
    SelectArrayInput,
    RadioButtonGroupInput,
    FileInput,
    FileField,
} from 'react-admin';
import { Typography, Box, Card, CardContent } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';

import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
    card: {
        maxWidth: 544,
        width: 544,
        minWidth: 300,
        // marginTop: '6em',
        // backgroundColor:'red',
    },
    image: { width: 544 },
    goal: { width: 544 },
    category: { width: 544 },
    file: { width: 544 },
    kcal: { width: 544 },
    name: { width: 544 },
    ingredients: { width: 544 },
    ecommerce: { display: 'inline-block', marginLeft: 32 },

    protein: { display: 'inline-block' },
    lipid: { display: 'inline-block', marginLeft: 32 },
    carbohydrates: { display: 'inline-block' },
    lactose: { display: 'inline-block', marginLeft: 32 },
    fruits: { display: 'inline-block' },
    vegetables: { display: 'inline-block', marginLeft: 32 },
};

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.customers.errors.password_mismatch',
        ];
    }

    return errors;
};

const MealCreate = (props: any) => {
    return (
        <Create {...props}>
            <MealForm />
        </Create>
    );
};

const EcommerceForm = (props: any) => {
    return (
        <Box display={{ md: 'block', lg: 'block' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput
                            type="number"
                            source="ecommerce.price"
                            inputProps={{
                                step: 1,
                                min: 1,
                            }}
                            validation={{ number: true }}
                            validate={requiredValidate}
                            label="resources.meals.fields.ecommerce.price"
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput
                            type="number"
                            source="ecommerce.calories"
                            inputProps={{
                                step: 1,
                                min: 1,
                            }}
                            validation={{ number: true }}
                            validate={requiredValidate}
                            label="resources.meals.fields.ecommerce.calories"
                            fullWidth
                        />
                    </Box>
                </Box>

                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput
                            type="number"
                            source="stock"
                            inputProps={{
                                step: 1,
                                min: 1,
                            }}
                            defaultValue={1}
                            validation={{ number: true }}
                            validate={requiredValidate}
                            fullWidth
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(styles);

const MealForm = (props: any) => {
    // const translate = useTranslate();
    const classes = useStyles();
    return (
        <Create {...props}>
            <SimpleForm>
                <RadioButtonGroupInput
                    source="goal"
                    label="resources.meals.fields.goal"
                    className={classes.goal}
                    choices={[
                        { id: 1, name: 'Sèche' },
                        { id: 2, name: 'Perte de poids' },
                        { id: 3, name: 'Prise de Masse' },
                        { id: 4, name: 'Remise en forme' },
                        { id: 5, name: 'Maintien' },
                    ]}
                    validate={requiredValidate}
                />
                 <NumberInput
                type="number"
                source="kcal"
                className={classes.kcal}
                inputProps={{
                    step: 1,
                    min: 1200,
                }}
                validation={{ number: true }}
                validate={requiredValidate}
                label="resources.meals.fields.kcal"
                
            />
                 <RadioButtonGroupInput
                    source="category"
                    label="resources.meals.fields.category"
                    className={classes.category}
                    validate={requiredValidate}
                    choices={[
                        { id: 'classique', name: 'Classique' },
                        { id: 'vegetarien', name: 'Vegetarien' },
                        { id: 'pesco_vegetarien', name: 'Pesco Vegetarien' },
                        { id: 'vegan', name: 'Vegan' }
                    ]}
                />
                <FileInput
                    source="file"
                    label="resources.meals.fields.file"
                    className={classes.file}
                    validate={requiredValidate}
                    accept="text/csv"
                >
                    <FileField source="file" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default MealCreate;
