import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    NumberInput,
    useTranslate,
    PasswordInput,
    DateInput,
    required,
    email,
} from 'react-admin';

import MapField from './MapField';


import { Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Styles } from '@material-ui/styles/withStyles';
import PureFullNameField from '../clients/FullNameField';

const validateEmail = email();

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    firstName: { display: 'inline-block' },
    lastName: { display: 'inline-block', marginLeft: 32 },
    name: { width: 544 },
    description: { width: 544 },

    email: { width: 544 },
    map: { width: 544 },
    // phone: { display: 'inline-block', marginLeft: 32 },

    phone: { display: 'inline-block' },
   password: { display: 'inline-block', marginLeft: 32 },
  
};

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.restaurants.errors.password_mismatch',
        ];
    }

    return errors;
};

const useStyles = makeStyles(styles);




const autoFillAddress = (event)=>{
    // if(event.restaurantAddress){
    //   if(event.restaurantAddress.length >= 5){
    //       event.latitude = latitude;
    //       event.longitude = longitude;
    //   }
    // }
  }



const RestaurantCreate = (props: any) => {
    const classes = useStyles();



    return (
        <Create {...props} >
            <SimpleForm redirect="list" >
                {/* <SectionTitle label="resources.restaurants.fields.image" /> */}
                <ImageInput
                    source="file"
                    label="Image"
                    labelSingle="resources.restaurants.image.upload_single"
                    accept="image/*"
                    multiple={false}
                    formClassName={classes.image}
                >
                 <ImageField accept="image/*" source="src" title="title" />
                </ImageInput>
                <Separator />
           
                <Separator />
                <TextInput
                  autoFocus
                    source="name"
                    fullWidth={true}
                    formClassName={classes.name}
                    validate={requiredValidate}
                    label="resources.restaurants.fields.name"
                />
                               <Separator />
                <TextInput
                  
                    source="first_name"
                    // fullWidth={true}
                    formClassName={classes.firstName}
                    validate={requiredValidate}
                    label="resources.restaurants.fields.first_name"
                />
                 <TextInput
                    source="last_name"
                    // fullWidth={true}
                    formClassName={classes.lastName}
                    validate={requiredValidate}
                    label="resources.restaurants.fields.last_name"
                />
                 <Separator />
                <MapField   source="location.coordinates"/>
                <Separator />
              
                 <TextInput
                    source="description"
                    fullWidth={true}
                    formClassName={classes.description}
                    // validate={requiredValidate}
                    label="resources.restaurants.fields.description"
                />
                <Separator />

                <TextInput
                    type="email"
                    source="email"
                    fullWidth={true}
                    formClassName={classes.email}
                    validate={[...requiredValidate,requiredValidate]}
                    label="resources.restaurants.fields.email"
                />
                  <Separator />
                 <TextInput
                    type="phone"
                    source="phone"
                    formClassName={classes.phone}
                    validate={requiredValidate}
                    label="resources.restaurants.fields.phone"
                />
               <PasswordInput
                    source="password"
                    formClassName={classes.password}
                    label="resources.restaurants.fields.password"
                /> 
                 {/* <PasswordInput
                    source="confirmPassword"
                    formClassName={classes.confirm_password}
                    label="resources.restaurants.fields.confirm_password"
                /> */}
                  <Separator />
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default RestaurantCreate;
