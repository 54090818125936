import React from 'react';
import {
    NumberField,
    TextField,
    DateField,
    useTranslate,
    useGetList,
    linkToRecord,
} from 'react-admin';
import PropTypes from 'prop-types';
import {
    Tooltip,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Avatar,
    IconButton,
    Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ContentCreate from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';


const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const Aside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

const EventList = ({ record, basePath }) => {
    const translate = useTranslate();


    return (
        <>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.customers.fieldGroups.history'
                            )}
                        </Typography>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.customers.fields.created_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="created_at"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.customers.fields.updated_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="updated_at"
                                        />
                                    </Box>
                                </Box>
                      
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

       
        </>
    );
};



const useEventStyles = makeStyles({
    card: {
        margin: '0 0 1em 1em',
    },
    cardHeader: {
        alignItems: 'flex-start',
    },
    clamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
});

// const Order = ({ record, basePath }) => {
//     const translate = useTranslate();
//     const classes = useEventStyles();
//     return (
//         <Card className={classes.card}>
//             <CardHeader
//                 className={classes.cardHeader}
//                 avatar={
//                     <Avatar
//                         aria-label={translate('resources.commands.name', {
//                             smart_count: 1,
//                         })}
//                         className={classes.avatar}
//                     >
//                         <order.icon />
//                     </Avatar>
//                 }
//                 action={<EditButton record={record} basePath="/commands" />}
//                 title={`${translate('resources.commands.name', {
//                     smart_count: 1,
//                 })} #${record.reference}`}
//                 subheader={
//                     <>
//                         <Typography variant="body2">{record.date}</Typography>
//                         <Typography variant="body2" color="textSecondary">
//                             {translate('resources.commands.nb_items', {
//                                 smart_count: record.basket.length,
//                                 _: '1 item |||| %{smart_count} items',
//                             })}
//                             &nbsp;-&nbsp;
//                             <NumberField
//                                 source="total"
//                                 options={{
//                                     style: 'currency',
//                                     currency: 'USD',
//                                 }}
//                                 record={record}
//                                 basePath={basePath}
//                             />
//                             &nbsp;-&nbsp;
//                             <TextField
//                                 source="status"
//                                 record={record}
//                                 basePath={basePath}
//                             />
//                         </Typography>
//                     </>
//                 }
//             />
//         </Card>
//     );
// };




export default Aside;
