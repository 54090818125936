import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    TextField,
    SearchInput,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';

import RecipientsField from './RecipientsField';
import SegmentInput from './SegmentInput';
import CustomerLinkField from './CustomerLinkField';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';
import NotificationShow from './NotificationShow';

const NotificationFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <DateInput source="created_at" />
        <NullableBooleanInput source="has_ordered" />
        <NullableBooleanInput source="has_newsletter" defaultValue />
        <SegmentInput /> */}
    </Filter>
);

const useStyles = makeStyles({
    nb_commands: { color: 'purple' },
});

const NotificationList = (props: any) => (
    <List {...props} filters={<NotificationFilter />} perPage={10}  >
        {/* <Datagrid rowClick="expand" expand={<NotificationShow />}    > */}
        <Datagrid rowClick="show" >
            <TextField
             source="message" 
             label="resources.notifications.fields.message" />
            <DateField 
            source="created_at"
            label="resources.notifications.fields.created_at" />
            <RecipientsField />
        </Datagrid>
    </List>
);


export default NotificationList;
